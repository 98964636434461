/**
 * A list of common medication names
 */
const drugNameList = [
    'Abilify',
    'Acamprosate',
    'acamprosate',
    'Acarbose',
    'Acetaminophen',
    'Acyclovir',
    'Adderall',
    'Admelog',
    'Advair',
    'Advil',
    'Albendazole',
    'Albuterol',
    'ALENDRONATE',
    'Aleve',
    'Alogliptin',
    'Alprazolam',
    'Amiodarone',
    'Amitriptyline',
    'Amlodine',
    'Amlodipine',
    'Amoxicillin',
    'Amphetamine',
    'Aspirin',
    'Aripiprazole',
    'Armodafinil',
    'Atarvastatin',
    'Atazanavir',
    'Atenolol',
    'Atomoxetine',
    'Atorvastatin',
    'Azelastine',
    'Baclofen',
    'Basgalar',
    'Belsomra',
    'Benzonatrate',
    'Benztropine',
    'Biotin',
    'Breztri',
    'Briviact',
    'Buprenorphine',
    'Bupropion',
    'Buspeirone',
    'Buspirone',
    'Cabergoline',
    'Candesartan',
    'Cannabis',
    'Captopril',
    'Carbamazepine',
    'Carvedilol',
    'Cefuroxime',
    'Celecoxib',
    'Cephalexin',
    'Cetirizine',
    'Chantix',
    'Chlordiazepoxide',
    'Cialis',
    'Ciprofloxacin',
    'Citalopram',
    'Claravis',
    'Clarithromycin',
    'Claritin',
    'Clindamycin',
    'Clomiphene',
    'Clonazepam',
    'Clondine',
    'Clonidine',
    'Clopidogrel',
    'Chlordiazepoxide',
    'Chlorthalidone',
    'Clotrimazole',
    'Clozapine',
    'Cyclobenzaprine',
    'Colchicine',
    'Colestipole',
    'Cyanocobalamin',
    'Cyclobenzaprine',
    'Delsym',
    'Depakote',
    'Descovy',
    'Desvenlafaxine',
    'Diazepam',
    'Diclofenac',
    'Dicyclomine',
    'Digoxin',
    'Diltiazem',
    'Diphenhydramine',
    'Diphenoxylate',
    'Disulfiram',
    'Donepezil',
    'Doxazosin',
    'Doxepin',
    'Doxycycline',
    'Duloxetine',
    'Effexor',
    'Eliquis',
    'Enalapril',
    'Epclusa',
    'Epinephrine',
    'Erythromycin',
    'Escitalopram',
    'Esomeprazole',
    'Eszopiclone',
    'Etodolac',
    'Euthyox',
    'Excedrin',
    'Famotidine',
    'Farxiga',
    'Fenofibrate',
    'Ferrous sulfate',
    'Finasteride',
    'Flovent',
    'Fluconazole',
    'Fluoxetine',
    'Fluticasone',
    'Folic Acid',
    'Furosemide',
    'Furosemide',
    'Gabapentin',
    'GasX',
    'Gemfibrozil',
    'Glimepiride',
    'Glipizide',
    'Glycopyrrolate',
    'Guanfacine',
    'Haloperidol',
    'Humalog',
    'Hydralazine',
    'Hydrochlorothiazide',
    'Hydrocodone',
    'Hydrocodone-Acetaminophen',
    'Hydrocortisone',
    'Hydromorphone',
    'Hydroxychloroquine',
    'Hydroxyzine',
    'Incruse',
    'Indomethacin',
    'Insulin',
    'Isentress',
    'Isosorbide',
    'Janumet',
    'Januvia',
    'Jardiance',
    'Keppra',
    'Ketorolac',
    'Labetalol',
    'Lamotrigine',
    'Lansoprazole',
    'Lantus',
    'Latuda',
    'Letrozole',
    'Levemir',
    'Levetiracetam',
    'Levofloxacin',
    'Levothroxine',
    'Lexapro',
    'Lidocaine',
    'Lisinopril',
    'Linzess',
    'Liothyronine',
    'Lisinopril',
    'Lithium',
    'Loperamide',
    'Loratadine',
    'Lorazepam',
    'Losartan',
    'Lovastatin',
    'Lyrica',
    'Magnesium',
    'Mavyret',
    'Meclizine',
    'Medrol',
    'Medroxyprogesterone',
    'Melatonin',
    'Meloxicam',
    'Metaxalone',
    'Metformin',
    'Methadone',
    'Methimazole',
    'Methylprednisolone',
    'Methocarbamol',
    'Methotrexate',
    'Methylphenidate',
    'Methylprednisolone',
    'Metoclopramide',
    'Metoprolol',
    'Metronidazole',
    'Minocycline',
    'MiraLax',
    'Mirtazapine',
    'Modafinil',
    'Montelukast',
    'Morphine',
    'Moxifloxacin',
    'Mucinex',
    'Mupirocin',
    'Mirabegron',
    'Nabumetone',
    'Naloxone',
    'Naltrexone',
    'Namzaric',
    'Naproxen',
    'Narcan',
    'Nicotine',
    'Nicotine Gum',
    'Nicotine Patch',
    'Nicotrol',
    'Nitrofurantoin',
    'Nitroglycerin',
    'Nortriptyline',
    'NovoLog',
    'NyQuil',
    'Ofloxacin',
    'Olanzapine',
    'Olmesartan',
    'Omeprazole',
    'Ondansetron',
    'Oxcarbazepine',
    'Oxybutynin',
    'Oxycodone',
    'Oxycontin',
    'Ozempic',
    'Paliperidone',
    'Pantoprazole',
    'Paroxetine',
    'Penicillin',
    'Pepcid',
    'Perphenazine',
    'Phenazopyridine',
    'Phenobarbital',
    'Phentermine',
    'Phenytoin',
    'Pioglitazone',
    'Polyethylene',
    'Polymyxin',
    'Potassium',
    'Pradaxa',
    'Pramipexole',
    'Prasugrel',
    'Pravastatin',
    'Prazosin',
    'Prednisone',
    'Pregabalin',
    'Prilosec',
    'Promethazine',
    'Propranolol',
    'Quetiapine',
    'Raltegravir',
    'Ranitidine',
    'Rexulti',
    'Risperidone',
    'Ritonavir',
    'Rizatriptan',
    'Ropinirole',
    'Rosuvastatin',
    'Saphris',
    'Semglee',
    'Seroquel',
    'Sertraline',
    'Sildenafil',
    'Simvastatin',
    'Sinex',
    'Spironolactone',
    'Spiriva',
    'Sprintec',
    'Suboxone',
    'Sucralfate',
    'Sulfamethoxazole',
    'Sulindac',
    'Sumatriptan',
    'Symbicort',
    'Synthroid',
    'Tadalafil',
    'Tamsulosin',
    'Tegretol',
    'Temazepam',
    'Terazosin',
    'Terbinafine',
    'Testosterone',
    'Tivicay',
    'Tizanidine',
    'Tobramycin',
    'Topiramate',
    'Torsemide',
    'Toujeo',
    'Tradjenta',
    'Trazodone',
    'Tramadol',
    'Tranexamic Acid',
    'Triamcinolone',
    'Triamterene',
    'Trifluoperazine',
    'rihexyphenidyl',
    'Trintellix',
    'Trulicity',
    'Tylenol',
    'Valacyclovir',
    'Valsartan',
    'Venlafaxine',
    'Ventolin',
    'Verapamil',
    'Victoza',
    'Viibryd',
    'Vivitrol',
    'Vraylar',
    'Vyvanse',
    'Wellbutrin',
    'Warfarin',
    'Xarelto',
    'Xopenex',
    'Xtampza',
    'Zaleplon',
    'Zinc',
    'Ziprasidone',
    'Zoloft',
    'Zolpidem',
    'Zyrtec'
];

export default drugNameList;
